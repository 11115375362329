// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
    --background-opacity: 0.8;
    --width: 100%;
    --height: fit-content;
    --background: hsla(240, 20%, 15%, 1);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}

ion-modal::part(content) {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (min-width: 40em) {

    ion-modal::part(content) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (min-width: 64em) {

    ion-modal::part(content) {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}

ion-modal::part(content) {

    margin-top: auto;
    border-top: 1px solid hsla(240, 20%, 50%, 1);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJlcmljaHQtZm9vdGVyLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSx5QkFBeUI7SUFDekIsYUFBYTtJQUNiLHFCQUFxQjtJQUNyQixvQ0FBbUM7SUFDbkMsa0NBQTBCO1lBQTFCLDBCQUEwQjtBQUM5Qjs7QUFHSTtJQUFBLGtCQUFzQjtJQUF0QixtQkFBc0I7QUFBQTs7QUFBdEI7O0lBQUE7UUFBQSxvQkFBc0I7UUFBdEI7SUFBc0I7QUFBQTs7QUFBdEI7O0lBQUE7UUFBQSxvQkFBc0I7UUFBdEI7SUFBc0I7QUFBQTs7QUFEMUI7O0lBR0ksZ0JBQWdCO0lBQ2hCLDRDQUEyQztBQUMvQyIsImZpbGUiOiJiZXJpY2h0LWZvb3Rlci5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLW1vZGFsIHtcbiAgICAtLWJhY2tncm91bmQtb3BhY2l0eTogMC44O1xuICAgIC0td2lkdGg6IDEwMCU7XG4gICAgLS1oZWlnaHQ6IGZpdC1jb250ZW50O1xuICAgIC0tYmFja2dyb3VuZDogdGhlbWUoXCJjb2xvcnMubi0yMDBcIik7XG4gICAgYmFja2Ryb3AtZmlsdGVyOiBibHVyKDRweCk7XG59XG5cbmlvbi1tb2RhbDo6cGFydChjb250ZW50KSB7XG4gICAgQGFwcGx5IGNvbnRhaW5lci1zcGFjZTtcblxuICAgIG1hcmdpbi10b3A6IGF1dG87XG4gICAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHRoZW1lKFwiY29sb3JzLm4tNTAwXCIpO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/bericht/component/bericht-footer/bericht-footer.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,qBAAqB;IACrB,oCAAmC;IACnC,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAGI;IAAA,kBAAsB;IAAtB,mBAAsB;AAAA;;AAAtB;;IAAA;QAAA,oBAAsB;QAAtB,qBAAA;IAAsB;AAAA;;AAAtB;;IAAA;QAAA,oBAAsB;QAAtB,qBAAA;IAAsB;AAAA;;AAD1B;;IAGI,gBAAgB;IAChB,4CAA2C;AAC/C;;AAuBA,o9BAAo9B","sourcesContent":["ion-modal {\n    --background-opacity: 0.8;\n    --width: 100%;\n    --height: fit-content;\n    --background: theme(\"colors.n-200\");\n    backdrop-filter: blur(4px);\n}\n\nion-modal::part(content) {\n    @apply container-space;\n\n    margin-top: auto;\n    border-top: 1px solid theme(\"colors.n-500\");\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
