// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parkour-article-card__action::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFydGlrZWwtY2FyZC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNDO0VBQ0MsV0FBVzs7RUFFWCxrQkFBa0I7RUFDbEIsTUFBTTtFQUNOLE9BQU87O0VBRVAsV0FBVztFQUNYLFlBQVk7Q0FDYiIsImZpbGUiOiJhcnRpa2VsLWNhcmQuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wYXJrb3VyLWFydGljbGUtY2FyZF9fYWN0aW9uIHtcblx0Jjo6YmVmb3JlIHtcblx0XHRjb250ZW50OiBcIlwiO1xuXG5cdFx0cG9zaXRpb246IGFic29sdXRlO1xuXHRcdHRvcDogMDtcblx0XHRsZWZ0OiAwO1xuXG5cdFx0d2lkdGg6IDEwMCU7XG5cdFx0aGVpZ2h0OiAxMDAlO1xuXHR9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/watwat/components/artikel-card/artikel-card.component.css"],"names":[],"mappings":"AACC;EACC,WAAW;;EAEX,kBAAkB;EAClB,MAAM;EACN,OAAO;;EAEP,WAAW;EACX,YAAY;CACb;;AACD,gjBAAgjB","sourcesContent":[".parkour-article-card__action {\n\t&::before {\n\t\tcontent: \"\";\n\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
