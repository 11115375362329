// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
	--background-opacity: 0.8;
	--width: 100%;
	--height: fit-content;
}

ion-modal::part(content) {
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (min-width: 40em) {

	ion-modal::part(content) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

@media (min-width: 64em) {

	ion-modal::part(content) {
		padding-left: 3.5rem;
		padding-right: 3.5rem;
	}
}

ion-modal::part(content) {
	max-width: 41.625rem;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

ion-modal::part(backdrop) {
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpZWwtc2VsZWN0LmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7Q0FDQyx5QkFBeUI7Q0FDekIsYUFBYTtDQUNiLHFCQUFxQjtBQUN0Qjs7QUFHQztDQUFBLGtCQUFzQztDQUF0QyxtQkFBc0M7QUFBQTs7QUFBdEM7O0NBQUE7RUFBQSxvQkFBc0M7RUFBdEM7Q0FBc0M7QUFBQTs7QUFBdEM7O0NBQUE7RUFBQSxvQkFBc0M7RUFBdEM7Q0FBc0M7QUFBQTs7QUFBdEM7Q0FBQSxvQkFBc0M7Q0FBdEMsV0FBc0M7Q0FBdEMsaUJBQXNDO0NBQXRDO0FBQXNDOztBQUd2QztDQUNDLGtDQUEwQjtTQUExQiwwQkFBMEI7QUFDM0IiLCJmaWxlIjoicHJvZmllbC1zZWxlY3QuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1tb2RhbCB7XG5cdC0tYmFja2dyb3VuZC1vcGFjaXR5OiAwLjg7XG5cdC0td2lkdGg6IDEwMCU7XG5cdC0taGVpZ2h0OiBmaXQtY29udGVudDtcbn1cblxuaW9uLW1vZGFsOjpwYXJ0KGNvbnRlbnQpIHtcblx0QGFwcGx5IGNvbnRhaW5lci0xLWNvbCBjb250YWluZXItc3BhY2U7XG59XG5cbmlvbi1tb2RhbDo6cGFydChiYWNrZHJvcCkge1xuXHRiYWNrZHJvcC1maWx0ZXI6IGJsdXIoMnB4KTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/profiel/component/profiel-select/profiel-select.component.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,aAAa;CACb,qBAAqB;AACtB;;AAGC;CAAA,kBAAsC;CAAtC,mBAAsC;AAAA;;AAAtC;;CAAA;EAAA,oBAAsC;EAAtC,qBAAA;CAAsC;AAAA;;AAAtC;;CAAA;EAAA,oBAAsC;EAAtC,qBAAA;CAAsC;AAAA;;AAAtC;CAAA,oBAAsC;CAAtC,WAAsC;CAAtC,iBAAsC;CAAtC,kBAAA;AAAsC;;AAGvC;CACC,kCAA0B;SAA1B,0BAA0B;AAC3B;;AA2BA,g4BAAg4B","sourcesContent":["ion-modal {\n\t--background-opacity: 0.8;\n\t--width: 100%;\n\t--height: fit-content;\n}\n\nion-modal::part(content) {\n\t@apply container-1-col container-space;\n}\n\nion-modal::part(backdrop) {\n\tbackdrop-filter: blur(2px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
