// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookies-modal-accordion {
	--padding-start: 0;

	background-color: transparent;
}

	.cookies-modal-accordion .item-inner {
		padding-inline-end: 0;
	}

.cookies-modal-accordion ion-item::part(native) {
	padding-inline-start: 0;
	border-width: 0;
}

ion-accordion.cookies-modal-accordion {
	overflow: visible;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvb2tpZXMtbW9kYWwuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtDQUNDLGtCQUFrQjs7Q0FFbEIsNkJBQTZCO0FBSzlCOztDQUhDO0VBQ0MscUJBQXFCO0NBQ3RCOztBQUdEO0NBQ0MsdUJBQXVCO0NBQ3ZCLGVBQWU7QUFDaEI7O0FBRUE7Q0FDQyxpQkFBaUI7QUFDbEIiLCJmaWxlIjoiY29va2llcy1tb2RhbC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvb2tpZXMtbW9kYWwtYWNjb3JkaW9uIHtcblx0LS1wYWRkaW5nLXN0YXJ0OiAwO1xuXG5cdGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuXG5cdC5pdGVtLWlubmVyIHtcblx0XHRwYWRkaW5nLWlubGluZS1lbmQ6IDA7XG5cdH1cbn1cblxuLmNvb2tpZXMtbW9kYWwtYWNjb3JkaW9uIGlvbi1pdGVtOjpwYXJ0KG5hdGl2ZSkge1xuXHRwYWRkaW5nLWlubGluZS1zdGFydDogMDtcblx0Ym9yZGVyLXdpZHRoOiAwO1xufVxuXG5pb24tYWNjb3JkaW9uLmNvb2tpZXMtbW9kYWwtYWNjb3JkaW9uIHtcblx0b3ZlcmZsb3c6IHZpc2libGU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/cookies-modal/cookies-modal.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;;CAElB,6BAA6B;AAK9B;;CAHC;EACC,qBAAqB;CACtB;;AAGD;CACC,uBAAuB;CACvB,eAAe;AAChB;;AAEA;CACC,iBAAiB;AAClB;;AAEA,gzBAAgzB","sourcesContent":[".cookies-modal-accordion {\n\t--padding-start: 0;\n\n\tbackground-color: transparent;\n\n\t.item-inner {\n\t\tpadding-inline-end: 0;\n\t}\n}\n\n.cookies-modal-accordion ion-item::part(native) {\n\tpadding-inline-start: 0;\n\tborder-width: 0;\n}\n\nion-accordion.cookies-modal-accordion {\n\toverflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
