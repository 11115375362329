export const environment = {
  production: false,
  API_BASE_URL: 'https://parkour-dev.opgroeien.be',
  watWatBaseUrl: 'https://www.watwat.be',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/14545545-2a96-42b5-8e4a-20a4eb1eeaa7',
  jitsiApiEmbedCode:
    'https://8x8.vc/vpaas-magic-cookie-20a1cb307fe84ca4837b4ae3ed22e0e9/external_api.js',
  ongelezenMeldingenInterval: 30000,
  opentelemetry: {
    enabled: false,
    tracingUrl: '',
  },
  logging: 'debug',
  matomo: {
    enabled: true,
    trackerUrl: 'https://matomo.parkour-dev.opgroeien.be/',
    siteId: 1,
  },
  mockGlobalHeader: false,
  debugOptionsEnabled: true,
};
