// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.artikels-preview__slide {
    --swiper-slide-opacity: 0.7;
    --swiper-slide-scale: 0.9;

    align-self: stretch;
    height: auto;

    opacity: var(--swiper-slide-opacity);

    transform: scale(var(--swiper-slide-scale));
}

.swiper-slide-active {
    --swiper-slide-opacity: 1;
    --swiper-slide-scale: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFydGlrZWxzLXByZXZpZXcuc3R5bGVzLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLDJCQUEyQjtJQUMzQix5QkFBeUI7O0lBRXpCLG1CQUFtQjtJQUNuQixZQUFZOztJQUVaLG9DQUFvQzs7SUFFcEMsMkNBQTJDO0FBQy9DOztBQUVBO0lBQ0kseUJBQXlCO0lBQ3pCLHVCQUF1QjtBQUMzQiIsImZpbGUiOiJhcnRpa2Vscy1wcmV2aWV3LnN0eWxlcy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXJ0aWtlbHMtcHJldmlld19fc2xpZGUge1xuICAgIC0tc3dpcGVyLXNsaWRlLW9wYWNpdHk6IDAuNztcbiAgICAtLXN3aXBlci1zbGlkZS1zY2FsZTogMC45O1xuXG4gICAgYWxpZ24tc2VsZjogc3RyZXRjaDtcbiAgICBoZWlnaHQ6IGF1dG87XG5cbiAgICBvcGFjaXR5OiB2YXIoLS1zd2lwZXItc2xpZGUtb3BhY2l0eSk7XG5cbiAgICB0cmFuc2Zvcm06IHNjYWxlKHZhcigtLXN3aXBlci1zbGlkZS1zY2FsZSkpO1xufVxuXG4uc3dpcGVyLXNsaWRlLWFjdGl2ZSB7XG4gICAgLS1zd2lwZXItc2xpZGUtb3BhY2l0eTogMTtcbiAgICAtLXN3aXBlci1zbGlkZS1zY2FsZTogMTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home/component/artikels-preview/artikels-preview.styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;;IAEzB,mBAAmB;IACnB,YAAY;;IAEZ,oCAAoC;;IAEpC,2CAA2C;AAC/C;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;AACA,40BAA40B","sourcesContent":[".artikels-preview__slide {\n    --swiper-slide-opacity: 0.7;\n    --swiper-slide-scale: 0.9;\n\n    align-self: stretch;\n    height: auto;\n\n    opacity: var(--swiper-slide-opacity);\n\n    transform: scale(var(--swiper-slide-scale));\n}\n\n.swiper-slide-active {\n    --swiper-slide-opacity: 1;\n    --swiper-slide-scale: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
