// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-radio {
  --background-color: hsla(240, 20%, 15%, 1);
  --border-color: hsla(240, 20%, 15%, 1);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  background-color: var(--background-color);
  border: 1.5px solid var(--border-color);
}

ion-radio::part(container) {
  display: none;
}

ion-radio.radio-checked {
    --background-color: hsla(240, 20%, 30%, 1);
    --border-color: hsla(240, 20%, 60%, 1);
  }

ion-radio::part(checked) {
  margin: 0;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvZWwtdGhlbWEuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDBDQUF5QztFQUN6QyxzQ0FBcUM7RUFFckMsK0ZBQXdCO0VBQXhCLHdEQUF3QjtFQUF4QiwwQkFBd0I7O0VBRXhCLHlDQUF5QztFQUN6Qyx1Q0FBdUM7QUFDekM7O0FBRUE7RUFDRSxhQUFhO0FBQ2Y7O0FBR0U7SUFDRSwwQ0FBeUM7SUFDekMsc0NBQXFDO0VBQ3ZDOztBQUdGO0VBQ0UsU0FBUztBQUNYIiwiZmlsZSI6ImRvZWwtdGhlbWEuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1yYWRpbyB7XG4gIC0tYmFja2dyb3VuZC1jb2xvcjogdGhlbWUoJ2NvbG9ycy5uLTIwMCcpO1xuICAtLWJvcmRlci1jb2xvcjogdGhlbWUoJ2NvbG9ycy5uLTIwMCcpO1xuXG4gIEBhcHBseSB0cmFuc2l0aW9uLWNvbG9ycztcblxuICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1iYWNrZ3JvdW5kLWNvbG9yKTtcbiAgYm9yZGVyOiAxLjVweCBzb2xpZCB2YXIoLS1ib3JkZXItY29sb3IpO1xufVxuXG5pb24tcmFkaW86OnBhcnQoY29udGFpbmVyKSB7XG4gIGRpc3BsYXk6IG5vbmU7XG59XG5cbmlvbi1yYWRpbyB7XG4gICYucmFkaW8tY2hlY2tlZCB7XG4gICAgLS1iYWNrZ3JvdW5kLWNvbG9yOiB0aGVtZSgnY29sb3JzLm4tMzAwJyk7XG4gICAgLS1ib3JkZXItY29sb3I6IHRoZW1lKCdjb2xvcnMubi02MDAnKTtcbiAgfVxufVxuXG5pb24tcmFkaW86OnBhcnQoY2hlY2tlZCkge1xuICBtYXJnaW46IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/doel/doel-shared/component/doel-thema/doel-thema.component.css"],"names":[],"mappings":"AAAA;EACE,0CAAyC;EACzC,sCAAqC;EAErC,+FAAwB;EAAxB,wDAAwB;EAAxB,0BAAwB;;EAExB,yCAAyC;EACzC,uCAAuC;AACzC;;AAEA;EACE,aAAa;AACf;;AAGE;IACE,0CAAyC;IACzC,sCAAqC;EACvC;;AAGF;EACE,SAAS;AACX;;AACA,gkCAAgkC","sourcesContent":["ion-radio {\n  --background-color: theme('colors.n-200');\n  --border-color: theme('colors.n-200');\n\n  @apply transition-colors;\n\n  background-color: var(--background-color);\n  border: 1.5px solid var(--border-color);\n}\n\nion-radio::part(container) {\n  display: none;\n}\n\nion-radio {\n  &.radio-checked {\n    --background-color: theme('colors.n-300');\n    --border-color: theme('colors.n-600');\n  }\n}\n\nion-radio::part(checked) {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
