// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-popover {
	--background: hsla(240, 20%, 30%, 1);
	--box-shadow: transparent;
}

	ion-popover::part(content) {
		border: 1.5px solid hsla(240, 20%, 50%, 50);
		overflow: visible;
	}

.bericht-message-popover {
	--offset-x: 0.625rem;
}

.bericht-message-popover-user {
	--offset-x: -10px;
}

.popover-content {
	border-radius: 0.5rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJlcmljaHQtbWVzc2FnZS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0NBQ0Msb0NBQW1DO0NBQ25DLHlCQUF5QjtBQU0xQjs7Q0FKQztFQUNDLDJDQUE0QztFQUM1QyxpQkFBaUI7Q0FDbEI7O0FBR0Q7Q0FDQyxvQkFBcUI7QUFDdEI7O0FBRUE7Q0FDQyxpQkFBc0I7QUFDdkI7O0FBRUE7Q0FDQyxxQkFBNEM7QUFDN0MiLCJmaWxlIjoiYmVyaWNodC1tZXNzYWdlLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tcG9wb3ZlciB7XG5cdC0tYmFja2dyb3VuZDogdGhlbWUoXCJjb2xvcnMubi0zMDBcIik7XG5cdC0tYm94LXNoYWRvdzogdHJhbnNwYXJlbnQ7XG5cblx0Jjo6cGFydChjb250ZW50KSB7XG5cdFx0Ym9yZGVyOiAxLjVweCBzb2xpZCB0aGVtZShcImNvbG9ycy5uLTUwMC81MFwiKTtcblx0XHRvdmVyZmxvdzogdmlzaWJsZTtcblx0fVxufVxuXG4uYmVyaWNodC1tZXNzYWdlLXBvcG92ZXIge1xuXHQtLW9mZnNldC14OiByZW0oMTBweCk7XG59XG5cbi5iZXJpY2h0LW1lc3NhZ2UtcG9wb3Zlci11c2VyIHtcblx0LS1vZmZzZXQteDogcmVtKC0xMHB4KTtcbn1cblxuLnBvcG92ZXItY29udGVudCB7XG5cdGJvcmRlci1yYWRpdXM6IHRoZW1lKFwiYm9yZGVyUmFkaXVzLkRFRkFVTFRcIik7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/bericht/component/bericht-message/bericht-message.component.css"],"names":[],"mappings":"AAAA;CACC,oCAAmC;CACnC,yBAAyB;AAM1B;;CAJC;EACC,2CAA4C;EAC5C,iBAAiB;CAClB;;AAGD;CACC,oBAAqB;AACtB;;AAEA;CACC,iBAAsB;AACvB;;AAEA;CACC,qBAA4C;AAC7C;;AAEA,o8BAAo8B","sourcesContent":["ion-popover {\n\t--background: theme(\"colors.n-300\");\n\t--box-shadow: transparent;\n\n\t&::part(content) {\n\t\tborder: 1.5px solid theme(\"colors.n-500/50\");\n\t\toverflow: visible;\n\t}\n}\n\n.bericht-message-popover {\n\t--offset-x: rem(10px);\n}\n\n.bericht-message-popover-user {\n\t--offset-x: rem(-10px);\n}\n\n.popover-content {\n\tborder-radius: theme(\"borderRadius.DEFAULT\");\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
