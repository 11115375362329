// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `parkour-radio-button {
  margin-top: 0.5rem;
}

.form-item {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUtZGV0YWlscy1wcm9maWVsbmFhbS1lZGl0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0Usa0JBQUE7QUFBRjs7QUFHQTtFQUNFLGFBQUE7RUFDQSxXQUFBO0VBQ0Esc0JBQUE7QUFBRiIsImZpbGUiOiJwcm9maWxlLWRldGFpbHMtcHJvZmllbG5hYW0tZWRpdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxucGFya291ci1yYWRpby1idXR0b24ge1xuICBtYXJnaW4tdG9wOiAwLjVyZW07XG59XG5cbi5mb3JtLWl0ZW0ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IDAuNXJlbTtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/profiel/component/profile-details-profielnaam-edit/profile-details-profielnaam-edit.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;AAAF;AACA,oiBAAoiB","sourcesContent":["\nparkour-radio-button {\n  margin-top: 0.5rem;\n}\n\n.form-item {\n  display: flex;\n  gap: 0.5rem;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
