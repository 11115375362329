// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-skeleton-text {
  --border-radius: 0.5rem;
  --background: hsla(240, 20%, 60%, 1);
}

.doel-list-item__action::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvZWwtbGlzdC1pdGVtLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSx1QkFBOEM7RUFDOUMsb0NBQW1DO0FBQ3JDOztBQUdFO0lBQ0UsV0FBVzs7SUFFWCxrQkFBa0I7SUFDbEIsTUFBTTtJQUNOLE9BQU87O0lBRVAsV0FBVztJQUNYLFlBQVk7RUFDZCIsImZpbGUiOiJkb2VsLWxpc3QtaXRlbS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLXNrZWxldG9uLXRleHQge1xuICAtLWJvcmRlci1yYWRpdXM6IHRoZW1lKCdib3JkZXJSYWRpdXMuREVGQVVMVCcpO1xuICAtLWJhY2tncm91bmQ6IHRoZW1lKCdjb2xvcnMubi02MDAnKTtcbn1cblxuLmRvZWwtbGlzdC1pdGVtX19hY3Rpb24ge1xuICAmOjpiZWZvcmUge1xuICAgIGNvbnRlbnQ6ICcnO1xuXG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogMDtcbiAgICBsZWZ0OiAwO1xuXG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/doel/doel-shared/component/doel-list-item/doel-list-item.component.css"],"names":[],"mappings":"AAAA;EACE,uBAA8C;EAC9C,oCAAmC;AACrC;;AAGE;IACE,WAAW;;IAEX,kBAAkB;IAClB,MAAM;IACN,OAAO;;IAEP,WAAW;IACX,YAAY;EACd;;AACF,wvBAAwvB","sourcesContent":["ion-skeleton-text {\n  --border-radius: theme('borderRadius.DEFAULT');\n  --background: theme('colors.n-600');\n}\n\n.doel-list-item__action {\n  &::before {\n    content: '';\n\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
