import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "parkour-page",
  templateUrl: "./parkour-page.component.html",
  standalone: true,
})
export class ParkourPageComponent {
  @Input({ required: true }) title!: string;
  @HostBinding("class") class = "ion-page";
}
