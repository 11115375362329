import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookmarkId, OrganisationId, validateOrganisationId } from 'parkour-web-app-dto';
import { teleOnthaalOrganisation, WatwatService } from '../../../shared/services/watwat.service';
import { map, Observable, switchMap } from 'rxjs';
import {
  ParkourButtonComponent,
  ParkourImageComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
} from '@parkour/ui';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { CoreModule } from '../../../core/core.module';
import { HelplineMethodsComponent } from '../../components/watwat-helplines/helpline-methods.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ArtikelContentComponent } from '../../components/artikel-component/artikel-content.component';
import {
  IShareArtikel,
  ShareButtonComponent,
} from '../../components/share-button/share-button.component';
import { BerichtenService } from '../../../bericht/service/berichten.service';
import { BookmarkButtonComponent } from '../../../shared/components/bookmark-button/bookmark-button.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { CustomPageTitle, PageTitle } from '../../../shared/custom-page-title';
import { OrganisationWithBookmark } from '../../../shared/model/artikel';

@Component({
  templateUrl: './hulplijn-page.component.html',
  standalone: true,
  imports: [
    HelplineCardItemComponent,
    CoreModule,
    HelplineMethodsComponent,
    ParkourBackButtonDirective,
    ArtikelContentComponent,
    ShareButtonComponent,
    ParkourImageComponent,
    ParkourButtonComponent,
    ParkourPageGradientComponent,
    BookmarkButtonComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonBackButton,
    PageLayoutComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    PageContentDirective,
  ],
})
export class HulplijnPage
  extends ParkourDataPage<OrganisationWithBookmark>
  implements CustomPageTitle
{
  hulplijnId!: OrganisationId;
  mostRecentOrganisations$ = new Observable<OrganisationWithBookmark[]>();
  standalone$: Observable<boolean> = this.route.data.pipe(
    map((data) => 'standalone' in data && data['standalone']),
  );
  protected readonly teleOnthaalOrganisation = teleOnthaalOrganisation;
  protected readonly String = String;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly watwatService: WatwatService,
    private readonly berichtenService: BerichtenService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    protected readonly toastService: ParkourToastService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<OrganisationWithBookmark> {
    return this.activatedRoute.paramMap.pipe(
      switchMap((queryParams) => {
        return this.watwatService
          .getOrganisationsOfIds([validateOrganisationId(queryParams.get('hulplijnId'))])
          .pipe(map((organisations) => organisations[0]));
      }),
    );
  }

  getCustomPageTitle(): Observable<PageTitle> {
    return this.generateDefaultTitle((data) => data.title, 'watwat.hulplijn-page.title');
  }

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this.mostRecentOrganisations$ = this.watwatService.getMostRecentOrganisations([
      this.hulplijnId,
    ]);
  }

  onClickedBookmark(
    hasBookmarked: boolean,
    hulplijnId: OrganisationId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.watwatService.addOrganisationBookmark(hulplijnId).subscribe();
    } else {
      if (bookmarkId) {
        this.watwatService.deleteOrganisationBookmark(bookmarkId).subscribe();
      }
    }
  }

  onShareHulplijn({ gesprekId, gesprekNaam, resourceId, profielId }: IShareArtikel) {
    this.berichtenService
      .stuurTekstBericht(gesprekId, {
        type: 'GEDEELDE_RESOURCE',
        resourceId,
        resourceType: 'HULPLIJN',
        deelnemerId: profielId,
      })
      .subscribe(() => {
        this.toastService.showToast({ header: `Hulplijn gedeeld met ${gesprekNaam}` });
      });
  }
}
